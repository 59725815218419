import React from "react";
import { Redirect, RouteComponentProps, StaticContext } from "react-router";

import { initializeDB } from "../db";
import FreefloatingLoginUpsell from "../FreefloatingLoginUpsell";

interface Props {
  redirectPath: string;
}

interface State {
  state: "pending" | "success";
}

type LoginProps = Props &
  RouteComponentProps<{}, StaticContext, { originalPath?: string }>;

export default class LoginRoute extends React.Component<LoginProps, State> {
  constructor(props: LoginProps) {
    super(props);
    this.state = { state: "pending" };
  }

  componentDidMount = () => {
    initializeDB();
  };

  onLogin = () => {
    this.setState({ state: "success" });
  };

  render = () => {
    if (this.state.state === "success") {
      let path =
        (this.props.location.state && this.props.location.state.originalPath) ||
        null;
      if (!path) {
        const searchParams = new URLSearchParams(this.props.location.search);
        path = searchParams.get("originalPath");
      }
      return <Redirect to={path || "/qcvc"} />;
    } else {
      return (
        <div className="StudySessionRoot">
          <title>Login | Quantum Country</title>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <FreefloatingLoginUpsell
              onLogin={this.onLogin}
              userState="anonymous"
              initialUserState="anonymous"
            />
          </div>
        </div>
      );
    }
  };
}
